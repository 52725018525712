




























































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {}
})

export default class Event extends Vue {
    static permission = AdminPermission.Event

    events: { _id: number, title: string, created: number }[] = []
    records: { member_id: string, display_name: string, value: number }[] = []

    eLoading = false
    rLoading = false

    newTitle: string = ''
    eventCollapse: string = ''

    newPhones: string = ''
    newAmount: number = 0
    redeemCollapse: string = ''

    currentEvent: { _id: number, title?: string, created?: number } = {_id: -1}
    async created() {
        await this.getEvents()
    }
    async getEvents() {
        this.eLoading = true
        const res = await createRequest('/events', 'get').send()
        this.events = res.data.events
        this.eLoading = false
    }
    async getRecords(id: number) {
        this.rLoading = true
        const res = await createRequest(`/event/${id}/redeemed`, 'get').send()
        this.records = res.data.records
        this.rLoading = false
    }
    async eventClicked(row, column, event) {
        this.currentEvent = row
        await this.getRecords(row._id)
    }
    async newEvent() {
        await createRequest(`/event`, 'post', {}, {title: this.newTitle}).send()
        this.newTitle = ''
        this.eventCollapse = ''

        this.records = []
        this.currentEvent = {_id: -1}
        await this.getEvents()
    }
    async redeem() {
        const phones = this.newPhones.split('\n').map((p) => {
            return p.replace(/ /g, '').trim()
        }).filter((p) => p !== '')
        const body = {
            phones: phones,
            amount: this.newAmount
        }
        const res = await createRequest(`/event/${this.currentEvent._id}/redeem`, 'post', {}, body).send()
        this.getRecords(this.currentEvent._id).then()
        if (res.data.failed!!.length) {
            this.newPhones = res.data.failed.join('\n')
            await this.$alert('Finished! \n\nFailed:\n' + res.data.failed.join('\n'), 'CAUTION', {
                confirmButtonText: 'OK'
            })
        } else {
            this.$message({
                message: 'Success',
                type: 'success'
            })
            this.redeemCollapse = ''
            this.newPhones = ''
        }
    }
}

