var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-main',[_c('el-row',[_c('el-col',{staticStyle:{"padding-right":"10px"},attrs:{"span":12}},[_c('el-card',[_c('el-collapse',{model:{value:(_vm.eventCollapse),callback:function ($$v) {_vm.eventCollapse=$$v},expression:"eventCollapse"}},[_c('el-collapse-item',{attrs:{"title":"New Event","name":"1"}},[_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('el-input',{staticStyle:{"padding-right":"10px"},attrs:{"size":"small","placeholder":"Title"},model:{value:(_vm.newTitle),callback:function ($$v) {_vm.newTitle=$$v},expression:"newTitle"}})],1),_c('el-col',{attrs:{"span":4}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"size":"small"},on:{"click":_vm.newEvent}},[_vm._v("Submit ")])],1)],1)],1)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.eLoading),expression:"eLoading"}],staticClass:"mb-3",attrs:{"data":_vm.events,"size":"small","highlight-current-row":true},on:{"row-click":_vm.eventClicked}},[_c('el-table-column',{attrs:{"prop":"title","label":"Title","align":"center"}}),_c('el-table-column',{attrs:{"label":"Created","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(new Date(row.created).toLocaleDateString('en-GB'))+" ")]}}])})],1)],1),_c('el-col',{attrs:{"span":12}},[(_vm.currentEvent._id !== -1)?_c('el-card',[_c('el-collapse',{model:{value:(_vm.redeemCollapse),callback:function ($$v) {_vm.redeemCollapse=$$v},expression:"redeemCollapse"}},[_c('el-collapse-item',{attrs:{"title":("Redeem (" + (_vm.currentEvent.title) + ")"),"name":"1"}},[_c('el-row',[_c('el-col',{attrs:{"span":18}},[_c('el-input',{staticStyle:{"padding-right":"10px"},attrs:{"type":"textarea","rows":3,"size":"small","placeholder":"Phones"},model:{value:(_vm.newPhones),callback:function ($$v) {_vm.newPhones=$$v},expression:"newPhones"}})],1),_c('el-col',{attrs:{"span":6}},[_c('el-input',{staticStyle:{"margin-bottom":"5px"},attrs:{"type":"number","size":"small","prefix-icon":"as-diamond diamond","placeholder":"Amount"},model:{value:(_vm.newAmount),callback:function ($$v) {_vm.newAmount=_vm._n($$v)},expression:"newAmount"}}),_c('el-button',{staticStyle:{"width":"100%"},attrs:{"size":"small"},on:{"click":_vm.redeem}},[_vm._v("Confirm")])],1)],1)],1)],1)],1):_vm._e(),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.rLoading),expression:"rLoading"}],staticClass:"mb-3",staticStyle:{"width":"100%"},attrs:{"data":_vm.records,"stripe":"","size":"small"}},[_c('el-table-column',{attrs:{"label":"Member","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('member-popover',{staticStyle:{"margin-right":"10px"},attrs:{"member-id":row.member_id,"size":"mini"}},[_vm._v(" "+_vm._s(row.display_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Phone","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone.replace('+852', ''))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Amount","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"diamond"},[_c('i',{staticClass:"as-diamond"}),_vm._v(" "+_vm._s(row.value)+" ")])]}}])})],1)],1)],1)],1),_c('el-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }